import * as React from "react"
import { Helmet } from "react-helmet"

import IDALayout from "../../../components/layouts/layout-ida"
import Seo from "../../../components/seo"

import EopCalloutCards from "../../../components/shared/generic/eop-callout-cards"

import akbcares from "../../../images/AkebiaCares-logo.png"
import CalloutIcon1 from "../../../images/icons/icon-callout-ida-dosing.png"
import CalloutIcon2 from "../../../images/icons/icon-callout-ida-connect.png"

import VideoInModal from "../../../components/shared/modal-video"

import MoaVidThumb from "../../../images/hp/resources/AKB_Auryxia_MOA_TitleCard.png"
import TeamVidThumb from "../../../images/ida/resources/Team-Approach-to-Treating-IDA@2x.png"
import IDAAccessFlashcard from "../../../images/ida/resources/AkebiaCares-IDA-Access-Flashcard_Thumb.jpg"
import IDAQuickHits from "../../../images/ida/resources/IDA_Quick_Hits_Thumb.jpg"
// import KidneyVidThumb from "../../images/ida/resources/Recognizing-National-Kidney-Month@2x.png"
import QuickHitsPDF from "../../../pdfs/IDA Quick Hit Brochure Update (Digital).pdf"
import AuryxiaPatientHandoutPDF from "../../../pdfs/Digital_IDA_Branded_Tearsheet_Final Upload.pdf"
import AuryxiaPatientHandoutThumb from "../../../images/ida/resources/Digital-IDA-Branded-Tearsheet.jpg"
import IdaPatientHandoutPDF from "../../../pdfs/Digital_IDA_Unbranded_Patient_Tearsheet_Final Upload.pdf"
import IdaPatientHandoutThumb from "../../../images/ida/resources/Digital-IDA-Unbranded-Patient-Tearsheet.jpg"

const ResourcesPage = ({ location }) => (
	<IDALayout
		location={location}
		locationHostName={location.hostname}
		jobCode="PP-AUR-US-1408 (v2.0)"
		jobCodeDate="03/22"
		references={[
			<>AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia Therapeutics, Inc.; 2021.</>]}
	>
		<Helmet>
			<html lang="en" />
			<link rel="canonical" href="https://www.auryxiahcp.com/iron-deficiency-anemia/about-auryxia/resources" />
			<body className="ida" id="ida-hcp-resources" />
		</Helmet>

		<Seo title="Resources" description="Find helpful resources for AURYXIA® (ferric citrate) tablets Read Important Safety Information and Prescribing Information on this page." />
		<section id="hero" className="center">
			<div className="flex-row">
				<div>
					<h1>Resources for AURYXIA</h1>
					<p>Find helpful resources including videos, brochures, and more.</p>
				</div>
			</div>
		</section>

		<section id="resources" className="col-10 center">
			<div className="flex-row">

				<div>
					<h2 className="ida">Mechanism of Action</h2>
					<p>See how AURYXIA, an oral iron tablet, works in adult patients with iron deficiency anemia.</p>
					{/* <VideoInModal
						accountId="5982844371001"
						playerId="eQq6v3QYZR"
						videoId="6189938842001"
						posterImg={MoaVidThumb}
						altText="Mechanism of Action"
						siteAudience="ida" /> */}
					<VideoInModal
						url = "https://www.youtube.com/embed/2trKJNelHYA"
						// width="100%" 
						// height="100%"
						posterImg={MoaVidThumb}
						altText="Mechanism of Action"
						siteAudience="ida"
					/>
				</div>
				<div>
					<h2 className="ida">A Team Approach to Treating Iron Deficiency Anemia</h2>
					<p>Find out why these health care professionals choose AURYXIA for their adult patients with iron deficiency anemia not on dialysis.<sup>1</sup></p>
					{/* <Link className="video" to="#">
						<img src={TeamVidThumb} alt="A Team Approach to Treating Iron Deficiency Anemia" />
					</Link>
					<Link to="#" className="cta ida"><span>WATCH NOW</span></Link> */}
					{/* <VideoInModal
						accountId="5982844371001"
						playerId="eQq6v3QYZR"
						videoId="6262904611001"
						posterImg={TeamVidThumb}
						altText="A Team Approach to Treating Iron Deficiency Anemia"
						siteAudience="ida" /> */}
					<VideoInModal
						url = "https://www.youtube.com/embed/ofO-dcujLT8"
						// width="100%" 
						// height="100%"
						posterImg={TeamVidThumb}
						altText="Mechanism of Action"
						siteAudience="ida"
					/>
				</div>

				{/* <div>
					<h2 className="ida">Recognizing National Kidney Month</h2>
					<p>Hear from your peers on why National Kidney Month is important month for them and their patients.</p>
					<VideoInModal
						accountId="5982844371001"
						playerId="eQq6v3QYZR"
						videoId="6259183559001"
						posterImg={KidneyVidThumb}
						altText="Recognizing National Kidney Month"
						siteAudience="ida" />
					
				</div> */}

				<div>
					<h2 className="ida">Access Support</h2>
					<p>Explore AURYXIA coverage specific to this indication.</p>
					<a href="https://www.auryxia.com/wp-content/uploads/AkebiaCares-IDA-Access-Flashcard.pdf" target="_blank" className="pdf" rel="noreferrer"><img src={IDAAccessFlashcard} alt="Access Support" /></a>
					<a href="https://www.auryxia.com/wp-content/uploads/AkebiaCares-IDA-Access-Flashcard.pdf" target="_blank" className="cta ida" rel="noreferrer"><span>DOWNLOAD</span></a>
				</div>

				<div>
					<h2 className="ida">AURYXIA Clinical Facts</h2>
					<p>Find out more about iron deficiency anemia and AURYXIA efficacy, safety, and access information.</p>
					<a href={QuickHitsPDF} target="_blank" className="pdf" rel="noreferrer"><img src={IDAQuickHits} alt="AURYXIA Clinical Facts" /></a>
					<a href={QuickHitsPDF} target="_blank" className="cta ida" rel="noreferrer"><span>DOWNLOAD</span></a>

				</div>

				<div>
					<h2 className="ida">AURYXIA Patient Handout</h2>
					<p>Help patients understand iron deficiency anemia and what to expect now that they have been prescribed AURYXIA.</p>
					<a href={AuryxiaPatientHandoutPDF} target="_blank" className="pdf" rel="noreferrer"><img src={AuryxiaPatientHandoutThumb} alt="AURYXIA Patient Handout" /></a>
					<a href={AuryxiaPatientHandoutPDF} target="_blank" className="cta ida" rel="noreferrer"><span>DOWNLOAD</span></a>

				</div>

				<div>
					<h2 className="ida">Iron Deficiency Anemia Patient Handout</h2>
					<p>Give patients the important facts about iron deficiency anemia, some of the symptoms, possible treatment options, and more.</p>
					<a href={IdaPatientHandoutPDF} target="_blank" className="pdf" rel="noreferrer"><img src={IdaPatientHandoutThumb} alt="Iron Deficiency Anemia Patient Handout" /></a>
					<a href={IdaPatientHandoutPDF} target="_blank" className="cta ida" rel="noreferrer"><span>DOWNLOAD</span></a>

				</div>
			</div>
		</section>


		<EopCalloutCards
			siteAudience="ida"
			callOneClass="highlight"
			callOneLink={"/iron-deficiency-anemia/dosing/"}
			callOneContent={
				<>
					<img src={CalloutIcon1} alt="" width="98" height="98" />
					<h3>Ready to prescribe?</h3>
					<p>AURYXIA provides the convenience of at-home treatment, so it&#x2019;s important to learn about dosing and administration.<sup>1</sup></p>
					<button className="cta ida"><span>GET DOSING INFORMATION</span></button>
				</>
			}

			callTwoClass="default"
			callTwoLink={"/iron-deficiency-anemia/sign-up?request-rep=true"}

			callTwoContent={
				<>
					<img src={CalloutIcon2} alt="" width="98" height="98" />
					<h3>Stay connected with AURYXIA</h3>
					<p>Whether you want to talk to a sales representative or order samples, we have you covered. Let us know what you&#x2019;re interested in and we&#x2019;ll be in touch.</p>
					<button className="cta akb"><span>GET STARTED</span></button>
				</>
			}
		/>

		<section id="crosslink-slab" className="akb akb_moa_section">
			<div className="flex-row">
				<div data-sal="zoom-in"
					data-sal-duration="600"
					data-sal-delay="200"
					data-sal-ease="ease-out-expo">
					<img src={akbcares} alt="AkebiaCares" height="81" />
					<h3>Your partner in helping patients access the medication they need</h3>
					<p className="boldTxt">Connect with a personal Case Manager today!</p>
					<p className="med_width">AkebiaCares personal Case Managers are standing by live to answer your call and help your patients find coverage.
                  </p>
					<ul className="info_ul">
						<li><a href="tel:18334253242">1&#xFEFF;-&#xFEFF;833&#xFEFF;-&#xFEFF;4AKEBIA&nbsp;(425&#xFEFF;-&#xFEFF;3242)</a></li>
						<li>Monday&nbsp;-&nbsp;Friday</li>
						<li className="hours_li">8<span>AM</span>&nbsp;–&nbsp;8<span>PM</span>&nbsp;EST</li>
					</ul>
					<a href="https://www.akebiacareshcp.com/" className="cta akb"><span>FIND SUPPORT OPTIONS</span></a>

				</div>
			</div>
		</section>

	</IDALayout>
)

export default ResourcesPage